.tts_controller {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;

    .stt_item {
        background: #FFF;
        border-radius: 6px;
        padding: 12px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: row;
        align-items: center;
        > div {
            flex: 1;
            padding: 0 10px;
        }
    }

    .stt_item + .stt_item {
        margin-top: 10px;
    }


    .controller__wrapper {
        background: #FFF;
        border-radius: 6px;
        padding: 12px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    }
    .form__row {
        display: flex;
        flex-direction: row;
        > * {
            flex: 1;
        }
        > * + * {
            margin-left: 24px;
        }
    }
}
