@import 'base';
@import 'sider';
@import "components/req";
@import "projects/req";
@import "views/req";

.ant-alert-error {
  background-color: #FF0000;
  border: 1px solid #ffa39e;
  color: #FFF;
}


.material--preview {
  display: flex;
  flex-direction: column;
  .btn--group {
    margin-bottom: 12px;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}


.media--view .media__item a.preview > div.image--expired, .media--list_item .image--expired {
  position: absolute;
  top: 28px;
  left: 4px;
  margin-left: 0 !important;
  border-radius: 4px;
  font-size: 12px;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #f50;
}

.media--list_item .image--expired {
  top: 4px;
}

.categories__report {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  .categories__report--item {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
    padding: 8px 12px;
    .category__title {
      font-weight: 600;
      font-size: 16px;
    }
    & + .categories__report--item {
      margin-left: 20px;
    }
  }
}


.pending {
  &:before{
      background: #FF;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
      top: 0;
      left: 0;
      content: "Идет обработка";
      color: #FFF;
      font-weight: bold;
      position: absolute;
      z-index: 2;
  }
}

.image__tag {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  margin-top: 4px;
  margin-left: 24px;
  .remove_checkbox {
    margin-left: 8px;
    margin-right: 0;
    .ant-checkbox-inner{
      background: #FF0000;
      border: 1px solid #FF0000;
    }
    
  }
}